<template>
  <div class="home">
    <div class="search-header">
      <el-form ref="searchForm" :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="">
          <el-input size="small" v-model="searchForm.name" placeholder="输入用餐人姓名"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select size="small" style="width: 140px;" clearable filterable v-model="searchForm.type" placeholder="选择报餐类型">
            <el-option v-for="(item,index) in typeList" :key="index" :label="item.text" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select size="small" style="width: 140px;" clearable filterable v-model="searchForm.mealStatus" placeholder="选择用餐状态">
            <el-option v-for="(item,index) in mealStatusList" :key="index" :label="item.text" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select size="small" style="width: 140px;" clearable filterable v-model="searchForm.reportStatus" placeholder="选择报餐状态">
            <el-option v-for="(item,index) in reportStatusList" :key="index" :label="item.text" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker
            size="small"
            style="width: 220px;"
            v-model="mealDateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleMealDate">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onSearchSubmit">查询</el-button>

          <el-button size="small" type="info" @click="handleExport" v-if="tableData.length>0">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-content">
      <el-table
        :data="tableData"
        size="small"
        border
        stripe
        style="width: 100%">
        <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="type"
          label="报餐类型"
          width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.type =='lunch'">午餐</span>
            <span v-else>晚餐</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mealStatus"
          label="用餐状态"
          width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.mealStatus ==0">报餐未吃</span>
            <span v-else-if="scope.row.mealStatus ==1">报餐已吃</span>
            <span v-else>报餐取消</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportStatus"
          label="报餐状态">
          <template slot-scope="scope">
            <span v-if="scope.row.reportStatus ==0">正常报餐</span>
            <span v-else-if="scope.row.reportStatus ==1">超时报餐</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dateCreate"
          label="报餐时间">
        </el-table-column>
        <el-table-column
          prop="takePeople"
          label="取餐人">
        </el-table-column>
        <el-table-column
          prop="note"
          label="备注">
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchForm.pn"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="searchForm.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { queryPageList,exportMeals } from '@/api/index'
const fileDownload = require("js-file-download");
export default {
  name: 'HomeView',
  components: {
    
  },
  data(){
    return {
      mealDateTime:null,
      searchForm:{
        pn:1,
        size:10,
        type:'',
        name:'',
        mealStatus:null,
        startDate:'',
        endDate:'',
        reportStatus:null
      },
      mealStatusList:[
        {
          text:'报餐未吃',
          value:'0'
        },
        {
          text:'报餐已吃',
          value:'1'
        },
        {
          text:'取消报餐',
          value:'2'
        },
      ],
      reportStatusList:[
        {
          text:'正常报餐',
          value:'0'
        },
        {
          text:'超时报餐',
          value:'1'
        },
        {
          text:'其他',
          value:'2'
        },
      ],
      typeList:[
        {
          text:'午餐',
          value:'lunch'
        },
        {
          text:'晚餐',
          value:'dinner'
        }
      ],
      tableData:[],
      tableTotal:0
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    async init(){
      let res = await queryPageList(this.searchForm)
      console.log(res)
      if(res.code == '200') {
        this.tableData = res.result
        this.tableTotal = res.total || 0
      }
    },
    onSearchSubmit(){
      this.init()
    },
    handleMealDate(val) {
      if(val) {
        this.searchForm.startDate = dayjs(val[0]).unix()
        this.searchForm.endDate = dayjs(val[1]).unix()
      }else{
        this.searchForm.startDate = null
        this.searchForm.endDate = null
      }
      
      this.init()
    },
    handleSizeChange(val) {
      this.searchForm.size = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.searchForm.pn = val;
      this.init();
    },

    handleExport(){
      this.$refs.searchForm.validate(async (valid) => {
        if (valid) {
          this.$confirm('导出数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.exportData();
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
          })
        }
      });
    },
    async exportData() {
      let searchForm = JSON.parse(JSON.stringify(this.searchForm));
      let res = await exportMeals(searchForm);
      if (res && !res.response) {
        fileDownload(res, '报餐数据统计列表' +dayjs().format('YYYY/MM/DD HH:mm:ss')+ '.xlsx');
      }
    },
  },
}
</script>
<style scoped>
.table-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
